import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLogIn } from '@hooks/useLogIn';
import { useNavigate } from '@hooks/useNavigate';
import { Routes } from '@shared/enums';

import { LoaderWrapper } from '@shared/ui/loader/LoaderWrapper';
import { useCabinetNavigate } from '@shared/hooks/useCabinetNavigate';
import { useSearchParams } from '@shared/hooks/useSearchParams';

import { User } from '@shared/types/common.types';
import { Loader } from '@shared/ui/loader/Loader';

import { useProcessIdGovUaRedirectMutation } from '@store/api/oauth.api';

const IdGovUaRedirectPage = () => {
  const { t } = useTranslation();

  const params = useSearchParams();

  const navigate = useNavigate();
  const cabinetNavigate = useCabinetNavigate();

  const logIn = useLogIn();

  const [mutation] = useProcessIdGovUaRedirectMutation();

  const [user, setUser] = useState<User | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!params) return;

    const [code, state] = [params.get('code'), params.get('state')];
    if (!code || !state) {
      setError('errors.code-state');

      return;
    }

    async function handleOauth(code: string, state: string) {
      try {
        const { token, payload } = await mutation({ code, state }).unwrap();

        logIn(payload, token);

        setUser(payload);
      } catch (err) {
        setError('errors.invalid-operation');
      }
    }

    handleOauth(code, state);
  }, []);

  useEffect(() => {
    if (!user) return;

    if (user.phoneNumber) {
      cabinetNavigate(user.type);
    } else {
      navigate(Routes.AuthMergeUsers);
    }
  }, [user]);

  if (error) {
    return (
      <div className="min-h-[300px]">
        <div className="absolute left-1/2 top-1/2 -translate-y-2/4 -translate-x-2/4 text-center">
          <p>
            {t('error-during-authorization')}: {t(error)}.
          </p>

          <p>{t('try-again-dot')}</p>
        </div>
      </div>
    );
  }

  return (
    <div>
      <LoaderWrapper className="flex flex-col items-center">
        <Loader />
        <span className="text-base mt-10">{t('authorization')}...</span>
      </LoaderWrapper>
    </div>
  );
};

export default IdGovUaRedirectPage;
