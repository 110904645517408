import { UserType } from '@shared/enums';
import { emptySplitApi } from '../emptySplitApi';
import { LogInResponse } from './types';

const extendedApi = emptySplitApi
  .enhanceEndpoints({
    addTagTypes: ['User', 'Applications'],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getIdGovUaAuthUrl: build.query<{ url: string }, { type: UserType.InNeed | UserType.Volunteer }>({
        query: ({ type }) => ({
          url: '/oauth/id-gov-ua/url',
          method: 'GET',
          params: {
            type,
          },
        }),
      }),
      processIdGovUaRedirect: build.mutation<LogInResponse, { code: string; state: string }>({
        query: (body) => ({
          url: '/oauth/id-gov-ua/redirect',
          method: 'POST',
          body,
        }),
        invalidatesTags: ['User', 'Applications'],
      }),
    }),
    overrideExisting: false,
  });

export const { useProcessIdGovUaRedirectMutation, useLazyGetIdGovUaAuthUrlQuery } = extendedApi;
